import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { getImage, getSrc } from 'gatsby-plugin-image';

import styled from 'styled-components';

import Image from '../Image';
import Link from '../Link';

import { breakpoint } from '../../theme';

const Thumbnail = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
`;

const Inner = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  background-color: ${(props) => props.theme.palette.background.light};
`;

const ThumbnailPlaceholder = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 120%;
  background-color: ${(props) => props.theme.palette.colors.grey};
`;

const Content = styled.div`
  position: relative;
  display: block;
  padding: ${(props) => props.theme.spacing.gutter};
  width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${rem(16)};

  p {
    line-height: 1.55;
    margin-bottom: ${rem(14)};
  }
`;

const Title = styled.h3`
  display: block;
  width: 100%;
  margin-bottom: ${rem(7)};
  font-size: ${rem(26)};
  color: ${(props) => props.theme.palette.colors.primary};

  /* ${breakpoint('large')} {
    font-size: ${rem(20)};
  } */
`;

const Details = styled.div`
  display: block;
  width: 100%;
  margin: 0;
  font-size: ${rem(16)};

  /* ${breakpoint('large')} {
    font-size: ${rem(25)};
  } */
`;

const Position = styled.span`
  display: block;
  font-size: ${rem(16)};
  color: ${(props) => props.theme.palette.colors.sixt};
  font-family: ${(props) => props.theme.typography.fonts.primary};
  font-weight: ${(props) => props.theme.typography.weights.primary.bold};
  letter-spacing: 0.01em;
  line-height: 1;
  margin-top: 0.5em;
  margin-bottom: 0.75em;
  text-transform: uppercase;
`;

const Phone = styled(Link)`
  display: block;
  margin-bottom: 0.1em;
  color: ${(props) => props.theme.palette.text.primary};
`;

const Email = styled(Link)`
  display: block;
  margin-bottom: 0.1em;
  color: ${(props) => props.theme.palette.text.primary};
  word-break: break-all;
`;

const Root = styled.div`
  width: 100%;
  transition: ${(props) =>
    `transform ${props.theme.global.transition.duration} ${props.theme.global.transition.timing}`};
`;

function StaffItem(props) {
  const { image, name, email, phone, title, withPlaceholder } = props;

  const itemImage = getImage(image?.localFile);
  const src = getSrc(image?.localFile);

  return (
    <Root>
      <Inner>
        {itemImage ? (
          <Thumbnail>
            <Image
              image={itemImage}
              fallback={{
                url: src,
                alt: image.altText,
              }}
              alt={image.altText}
            />
          </Thumbnail>
        ) : (
          withPlaceholder && <ThumbnailPlaceholder />
        )}

        <Content>
          <Title>{name}</Title>
          <Details>
            {title && <Position>{title}</Position>}
            {phone && <Phone to={`tel:${phone}`}>{phone}</Phone>}
            {email && <Email to={`mailto:${email}`}>{email}</Email>}
          </Details>
        </Content>
      </Inner>
    </Root>
  );
}

StaffItem.propTypes = {
  name: PropTypes.node.isRequired,
  image: PropTypes.shape({
    localFile: PropTypes.oneOfType([PropTypes.object]),
    source_url: PropTypes.string,
    altText: PropTypes.string,
    title: PropTypes.string,
  }),
  title: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  withPlaceholder: PropTypes.bool,
};

StaffItem.defaultProps = {
  image: null,
  title: '',
  phone: '',
  email: '',
  withPlaceholder: false,
};

export default StaffItem;
