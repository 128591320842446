import { graphql } from 'gatsby';

export const Fragment = graphql`
  fragment StaffItems on WpStaffConnection {
    edges {
      node {
        id
        featuredImage {
          node {
            altText
            caption
            localFile {
              id
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  height: 960
                  quality: 75
                  transformOptions: { cropFocus: ENTROPY }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        excerpt
        title
        uri
        lang
        staffDetails {
          email
          phone
          title
        }
      }
    }
  }
`;

export default Fragment;
