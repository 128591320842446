import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { getSrc } from 'gatsby-plugin-image';

import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import { GridContainer } from '../components/Grid';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';
import StaffItem from '../components/StaffItem';

import { breakpoint } from '../theme';
import { SiteInformation, PageContent, StaffItems } from '../utils/fragments';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(0)} auto 0;
  padding-top: ${rem(50)};
  /* padding-bottom: ${rem(100)}; */
`;

const PostContent = styled.div`
  font-size: ${rem(16.5)};

  ${breakpoint('large')} {
    font-size: ${rem(18)};
  }

  & .wp-block-image {
    margin-top: ${rem(32)};
    margin-bottom: ${rem(22)};
  }

  & figcaption {
    color: ${(props) => props.theme.palette.colors.fadedText};
    font-size: ${rem(14)};
    font-weight: ${(props) => props.theme.typography.weights.primary.normal};
    font-family: ${(props) => props.theme.typography.fonts.primary};
  }
`;

const StaffRow = styled(Row)`
  position: relative;
  padding-top: ${rem(20)};
  padding-bottom: ${rem(40)};

  ${breakpoint('large')} {
    padding-bottom: ${rem(70)};
  }
`;

const StaffCol = styled(Col)`
  position: relative;
  z-index: 1;
  display: block;
  margin-bottom: 2.5rem;
  width: 100%;

  ${breakpoint('mediumlarge')} {
    width: 33.333%;
  }

  ${breakpoint('large')} {
    width: 33.333%;
  }
`;

function Staff({ data }) {
  const { page, articles } = data;

  useLangRedirect(page);

  if (!page) {
    return null;
  }

  const {
    featuredImage,
    elementorDataRendered,
    elementorEditMode,
    lang,
    seo,
    pageOptions: { heroSize },
  } = page;

  const isCustomLayout = elementorEditMode === 'builder';
  const featuredMedia = featuredImage?.node;
  const itemImageSrc = getSrc(featuredMedia?.localFile);
  const withHero = true;

  return (
    <Layout headerProps={{ toggleLogoOnStuck: heroSize !== 'default' }}>
      <SEO
        title={seo.title}
        description={seo.metaDesc || seo.opengraphDescription}
        image={itemImageSrc}
        page={page}
        lang={lang}
      />

      {withHero && (
        <Hero
          heading={page.title}
          image={featuredMedia?.localFile}
          pageTitle
          // actions="Actions"
          tint
          big={heroSize === 'big'}
          logo={heroSize === 'big'}
        />
      )}

      {isCustomLayout ? (
        <div
          dangerouslySetInnerHTML={{ __html: elementorDataRendered }} // eslint-disable-line react/no-danger
        />
      ) : (
        <>
          {page.content && (
            <Container>
              <Row>
                <Col>
                  <PostContent
                    dangerouslySetInnerHTML={{ __html: page.content }} // eslint-disable-line react/no-danger
                  />
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}
      <GridContainer>
        <StaffRow>
          {articles.edges.map(({ node: item }) => (
            <StaffCol key={item.id}>
              <StaffItem
                image={item.featuredImage?.node}
                name={item.title}
                title={item.staffDetails?.title}
                email={item.staffDetails?.email}
                phone={item.staffDetails?.phone}
              />
            </StaffCol>
          ))}
        </StaffRow>
      </GridContainer>
    </Layout>
  );
}

Staff.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

Staff.fragments = [PageContent, SiteInformation, StaffItems];

export const query = graphql`
  query ($id: String!, $lang: String!) {
    page: wpPage(id: { eq: $id }) {
      elementorDataRendered
      elementorEditMode
      ...PageContent
    }
    articles: allWpStaff(
      filter: { lang: { eq: $lang } }
      sort: { fields: menuOrder, order: ASC }
    ) {
      ...StaffItems
    }
    site {
      ...SiteInformation
    }
  }
`;

export default Staff;
