import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { rem } from 'polished';

// import { breakpoint } from '../../theme';

const Container = styled.div`
  width: 100%;
  margin: ${rem(0)} auto 0;
  max-width: ${({ theme, narrow }) =>
    narrow === true ? theme.grid.maxWidthText : theme.grid.maxWidth};

  ${({ padded }) =>
    padded &&
    css`
      padding-top: ${rem(50)};
      padding-bottom: ${rem(100)};
    `}
`;

function GridContainer({ narrow, ...other }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Container narrow={narrow} {...other} />;
}

GridContainer.propTypes = {
  narrow: PropTypes.bool,
};

GridContainer.defaultProps = {
  narrow: false,
};

export default Container;
